import AButton from "aloha-vue/src/AButton/AButton";
import Modal from "../../Modal/Modal.vue";

import {
  cloneDeep,
  forEach,
  forOwn,
  isArray,
} from "lodash-es";

// @vue/component
export default {
  name: "TableModalWorkflowMassActionsClientFunction",
  components: {
    AButton,
    Modal,
  },
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    workflowObj: {
      type: Object,
      default: () => ({}),
    },
    pks: {
      type: Array,
      default: () => {},
    },
    selectorClose: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    selectorCloseIds: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      title: "",
      buttonName: "",
      buttonIcon: "",
      buttonClassName: "",
    };
  },
  computed: {
    getButtonClass() {
      return this.workflowObj.wfo_button_class || "a_btn a_btn_secondary";
    },
  },
  created() {
    this.initOptions();
  },
  methods: {
    initOptions() {
      const CLIENTFUNKTION = this.workflowObj.clientfunktion;
      if (CLIENTFUNKTION) {
        if (CLIENTFUNKTION.wfc_parameterin) {
          this.initTitle({ wfc_parameterin: CLIENTFUNKTION.wfc_parameterin });
          this.initOptionsMsg({ wfc_parameterin: CLIENTFUNKTION.wfc_parameterin });
        }
        if (CLIENTFUNKTION.wfc_parameterout) {
          this.initFormular({ wfc_parameterout: CLIENTFUNKTION.wfc_parameterout });
        }
      }
    },

    initTitle({ wfc_parameterin }) {
      if (wfc_parameterin.title) {
        this.title = wfc_parameterin.title.wert;
      }
    },

    initOptionsMsg({ wfc_parameterin }) {
      if (wfc_parameterin.msg) {
        this.options.list.push({
          type: "readonly",
          id: "wfc_parameterin_msg",
          text: wfc_parameterin.msg.wert,
        });
      }
    },

    getFormular({ wfc_parameterout }) {
      return wfc_parameterout.formular ? wfc_parameterout.formular.wert : wfc_parameterout;
    },

    initFormular({ wfc_parameterout }) {
      const formular = this.getFormular({ wfc_parameterout: wfc_parameterout });
      forOwn(formular, (value, key) => {
        const el = {
          label: value.bez,
          type: value.typ,
          id: key,
          required: !!(value.options && value.options.required),
          view: "v-alt",
        };
        if (value.typ === "single_choice" || value.typ === "multi_select") {
          value.view = "v";
          if (value.choices) {
            el.data = value.choices;
          } else if (value.katalog) {
            el.url = `katalog/?fields=id&fields=bez&key=${ value.katalog }`;
            if (value.katalog_filter) {
              const filter_params = Object.keys(value.katalog_filter)
                .filter(key => value.katalog_filter[key] !== null && value.katalog_filter[key] !== undefined)
                .map(key => {
                  if (isArray(value.katalog_filter[key])) {
                    return value.katalog_filter[key]
                      .map(el => `${ encodeURIComponent(key) }=${ encodeURIComponent(el) }`)
                      .join("&");
                  }
                  return `${ encodeURIComponent(key) }=${ encodeURIComponent(value.katalog_filter[key]) }`;
                })
                .join("&");
              el.url = `${ el.url }&${ filter_params }`;
            }
          }
        }
        this.options.list.push(el);
      });
    },

    save() {
      const DATA = {
        objects: this.pks,
        workflowfolge: this.workflowObj.pk,
      };
      if (this.workflowObj.clientfunktion && this.workflowObj.clientfunktion.wfc_parameterout) {
        const formular = this.getFormular({ wfc_parameterout: this.workflowObj.clientfunktion.wfc_parameterout });
        DATA.params = cloneDeep(formular);
        forEach(this.model, (item, key) => {
          if (DATA.params[key]) {
            DATA.params[key].wert = item;
          }
        });
      }
      const MULTIPLE_ACTION_OBJ = {
        label: this.workflowObj.wfo_kbez,
        workflowFunction: true,
      };
      this.close({ data: DATA, multipleActionObj: MULTIPLE_ACTION_OBJ });
    },
  },
};
